import Parse from 'parse';
import { I18n } from 'react-i18nify';
import en from './locales/en';
import he from './locales/he';
import BigCalendar from 'react-big-calendar'
// import moment from 'moment';
import moment from '../config/momentConfig';
import { log } from '../api/Log'

function setRTL() {
  document.documentElement.setAttribute('dir', 'rtl');
  document.body.style.textAlign = 'right';
}

function setLTR() {
  document.documentElement.setAttribute('dir', 'ltr');
  document.body.style.textAlign = 'left';
}

function setLanguageToLocalStorage(lang) {
  localStorage.setItem('medidate_lang', lang);
}

function getLanguageToLocalStorage() {
  return localStorage.getItem('medidate_lang');
}

function selectTempLanguage(lang) {
  I18n.setLocale(lang, true);
  moment.locale(lang);
}

I18n.setLanguage = ((lang) => {
  log('setLanguage', lang);
  lang = lang.slice(0, 2);
  if (lang != 'en' && lang != 'he')
    lang = 'en'

  I18n.setLocale(lang, true);
  moment.locale(lang);

  //Change from latest version of library (moved to constructor in Calendar) 
  try {
    BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
  } catch (error) {

  }

  setLanguageToLocalStorage(lang);

  if (lang === 'he') {
    setRTL();
  } else {
    setLTR();
  }

  return lang;
});

I18n.setTempLanguage = ((lang) => {
  log('setTempLanguage', lang);
  lang = lang.slice(0, 2);
  if (lang != 'en' && lang != 'he')
    lang = 'en'

  I18n.setLocale(lang, false);
  moment.locale(lang);

  if (lang === 'he') {
    setRTL();
  } else {
    setLTR();
  }

  return lang;
});

I18n.toggleLanguage = () => {
  log('toggle')
  I18n.setLanguage(I18n._localeKey === 'he' ? 'en' : 'he');
}

I18n.changeLanguageFromSelect = ((evt) => {
  log('toggle', evt);
  I18n.setLanguage(evt.value === 'US' ? 'en' : 'he');
});

I18n.changeLanguage = ((evt) => {
  log('toggle', evt);
  I18n.setLanguage(evt === 'US' ? 'en' : 'he');
});

I18n.setTranslations({ en, he });
var initLocale = getLanguageToLocalStorage() || 'en';
I18n.setLanguage(initLocale)

export function showTextByBusinessType(key, wordingsParams, externalUser, selectedBusinessType) {

  function replaceAll(str, find, replace) {
    if (str === undefined || str.length == 0)
      return str;
    if (find === undefined || find.length == 0)
      return str;
    if (replace === undefined || replace.length == 0)
      return str;
    if (typeof str === 'string' || str instanceof String)
      return str.replace(new RegExp(find, 'g'), replace);

    return str;
  }

  let text = wordingsParams ? I18n.t(key, wordingsParams) : I18n.t(key);

  var referenceUser = externalUser ? externalUser : Parse.User.current();
  if (referenceUser && referenceUser.get("preferences") || selectedBusinessType) {
    let businessType = selectedBusinessType ? selectedBusinessType : referenceUser.get('preferences').get('business_type');
    // businessType = 2;

    switch (businessType) {
      case 0://Studio
        break
      case 1://Center
        text = replaceAll(text, "studio", "center");
        text = replaceAll(text, "Studio", "Center");
        text = replaceAll(text, "סטודיו", "מרכז");
        break
      case 2://Clinic
        text = replaceAll(text, "studio", "clinic");
        text = replaceAll(text, "Studio", "Clinic");
        text = replaceAll(text, "סטודיו זה מנהל", "קליניקה זו מנהלת");
        text = replaceAll(text, "סטודיו זה", "קליניקה זו");
        text = replaceAll(text, "סטודיו", "קליניקה");
        text = replaceAll(text, "teacher", "therapist");
        text = replaceAll(text, "Teacher", "Therapist");
        text = replaceAll(text, "מורים", "מטפלים/ות");
        text = replaceAll(text, "מורה", "מטפל/ת");
        text = replaceAll(text, "student", "patient");
        text = replaceAll(text, "Student", "Patient");
        text = replaceAll(text, "סטודנטים", "מטופלים");
        text = replaceAll(text, "סטודנט", "מטופל");
        text = replaceAll(text, "תלמידים", "מטופלים");
        text = replaceAll(text, "תלמיד/ה", "מטופל/ת");
        text = replaceAll(text, "תלמיד", "מטופל");
        text = replaceAll(text, "class", "session");
        text = replaceAll(text, "Class", "Session");
        text = replaceAll(text, "שיעור", "טיפול");
        text = replaceAll(text, "תרגל", "טופל");
        break
      case 3://Business
        text = replaceAll(text, "studio", "business");
        text = replaceAll(text, "Studio", "Business");
        text = replaceAll(text, "סטודיו", "עסק");
        text = replaceAll(text, "teacher", "seller");
        text = replaceAll(text, "Teacher", "Seller");
        text = replaceAll(text, "מורים", "מוכרים/ות");
        text = replaceAll(text, "מורה", "מוכר/ת");
        text = replaceAll(text, "student", "client");
        text = replaceAll(text, "Student", "Client");
        text = replaceAll(text, "סטודנטים", "לקוחות");
        text = replaceAll(text, "סטודנט", "לקוח");
        text = replaceAll(text, "תלמידים", "לקוחות");
        text = replaceAll(text, "תלמיד/ה", "לקוח/ה");
        text = replaceAll(text, "תלמיד", "לקוח");
        text = replaceAll(text, "class", "item");
        text = replaceAll(text, "Class", "Item");
        text = replaceAll(text, "שיעור", "פריט");
        text = replaceAll(text, "תרגל", "רכש");
        break;
      case 4://Sports Club
        text = replaceAll(text, "studio", "sport club");
        text = replaceAll(text, "Studio", "Sport Club");
        text = replaceAll(text, "סטודיו", "מועדון ספורט");
        text = replaceAll(text, "teacher", "trainer");
        text = replaceAll(text, "Teacher", "Trainer");
        text = replaceAll(text, "מורים", "מאמנים/ות");
        text = replaceAll(text, "מורה", "מאמן/ת");
        text = replaceAll(text, "student", "trainee");
        text = replaceAll(text, "Student", "Trainee");
        text = replaceAll(text, "סטודנטים", "מתאמנים/ות");
        text = replaceAll(text, "סטודנט", "מתאמן/ת");
        text = replaceAll(text, "תלמידים", "מתאמנים/ות");
        text = replaceAll(text, "תלמיד/ה", "מתאמן/ת");
        text = replaceAll(text, "תלמיד", "מתאמן/ת");
        text = replaceAll(text, "class", "practice");
        text = replaceAll(text, "Class", "Practice");
        text = replaceAll(text, "שיעורים", "אימונים");
        text = replaceAll(text, "שיעור", "אימון");
        text = replaceAll(text, "תרגל", "התאמן/נה");
        break;
      default:
        break;
    }
  }

  return text
}

window.showTextByBusinessType = showTextByBusinessType;

window.I18n = I18n;
